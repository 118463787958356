<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model='filters.name' placeholder='输入姓名/手机号/身份证号' clearable @clear='getData'></el-input>
                    </el-form-item>
                    <el-form-item label='课程名称：'>
                        <el-select v-model="filters.course" placeholder="请选择" clearable @clear='getData'>
                            <el-option
                            v-for="item in options"
                            :key="item"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='所选行业：'>
                        <el-select v-model="filters.industry" placeholder="请选择" clearable @clear='getData'>
                            <el-option
                            v-for="item in options"
                            :key="item"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='培训机构：'>
                        <el-select v-model="filters.trainingInstitutions" placeholder="请选择" clearable @clear='getData'>
                            <el-option
                            v-for="item in options"
                            :key="item"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        :data="tableData"
        highlight-current-row
        @current-change='selectCurrentRow'
        style="width: 95%">
            <el-table-column type='index' label="序号" width="60" align='center'></el-table-column>
            <el-table-column prop='d' label="姓名" width="" align='center'></el-table-column>
            <el-table-column prop='e' label="身份证号" width="" align='center'></el-table-column>
            <el-table-column prop='f' label="手机号" width="" align='center'></el-table-column>
            <el-table-column prop='g' label="所选培训机构" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='h' label="价格" width="" align='center'></el-table-column>
            <el-table-column prop='a' label="订单号" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='b' label="课程名称" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='c' label="所选行业" width="" align='center' show-overflow-tooltip></el-table-column>           
            <el-table-column prop='i' label="支付时间" width="" align='center' show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.total">
      </el-pagination>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            filters:{
                name:'',
                course:'',
                industry:'',
                trainingInstitutions:''
            },
            options:5,
            tableData:[
              {a:'12310',b:'消防安全责任人',c:'商场市场、大型商业综合体',d:'张三',e:'18888888888',f:'410381111111049043',g:'广州越秀消防培训学校',h:'500',i:'2021/6/15 15:17:53'}
            ],
            pages:{
              pageSize:20,
              pageIndex:1,
              total:0
            },
            currentRow:{}
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 选择当前行
        selectCurrentRow(row){
          this.currentRow = row
        },
        // 分页
        handleSizeChange(value){
          this.pages.pageSize = value
          this.getData()
        },
        handleCurrentChange(value){
          this.pages.pageIndex = value
          this.getData()
        },
        // 查询
        find(){
          this.pages.pageIndex = 1
          this.getData()
        },
        // 获取列表数据
        getData(){
          var params = {}
          // 'xxx'(params).then(res =>{

          // }).catch(() =>{})
        }
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>